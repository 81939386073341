import React,{Fragment,useState,useEffect} from 'react'
import { Steps, Button,Spin,Row,Col,Layout,Modal,Badge,FloatButton,notification  } from 'antd';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { QuestionCircleTwoTone,SmileOutlined,ShopOutlined,ShoppingCartOutlined,SolutionOutlined,SafetyOutlined,DollarOutlined ,InfoCircleOutlined} from '@ant-design/icons';
import Clientes from './Clientes';
import SelectProducto from './SelectProducto';
import Carrito from './Carrito';  
import DatosAdicionales from './DatosAdicionales';
import Confirmacion from './Confirmacion'
import VendedorMenu from './VendedorMenu';

function Pedidonuevo() {
    var width = window.innerWidth;

    const urlFolio = process.env.REACT_APP_URL_API+"/getFolio.php";    
    const urlPedidos1 = process.env.REACT_APP_URL_API+"/insertPedidoPartida_Vendedor.php";
    const urlPedidos2 = process.env.REACT_APP_URL_API+"/insertPedido_Vendedor.php";
    const urlGetSaldo = process.env.REACT_APP_URL_API+"/API_getSaldo.php";
    const urlAdjuntos = process.env.REACT_APP_URL_API+"/insertPedido_Adjuntos.php";
    const baseURL_ClientesDescuento = process.env.REACT_APP_URL_API+"/getDescuentoMatriz.php";

    const cookies = new Cookies();
    const { Header, Footer,  Content } = Layout;
    const { Step } = Steps;
    const { confirm } = Modal;

    const [current, setCurrent] = React.useState(0);
    const [stepVisibility, setStepVisibility] = useState({
        cliente:'inline',
        productos:'none',
        carrito:'none',
        datos:'none',
        confirmacion:'none',
    });

    
    const [state,setState] = useState({
        loading : false
    });
    const [carrito,setCarrito] = useState([]);

    const [adjuntos,setAdjuntos] = useState([]);

    const [header, setHeader] = useState({
        tipo:'Pedido',
        marca:'Automagic',
        cliente:'',
        matriz:'',
        lp:'6',
        descuento:'',
        diascredito:'0',
        saldoCliente:'0',
    });

    const[datosOpcionales, setDatosOpcionales]=useState({
        formaPago:'',
        formaEnvio:'',
        observaciones:'',
        noguia:'',
        paqueteria:'',
        oc:'',
        condicion:'',
    });

    const [productosCargados, setProductosCargados] = useState(false);
    const [folio,setFolio] = useState({
        folioVenta:'',
    });

    var steps = [
            {
                title: 'Cliente',
                percent: 20,
                content: <Clientes header={header} setHeader={setHeader}></Clientes>,            
                subTitle:'',
                icon:<SmileOutlined />,
                style: {display:stepVisibility.cliente},
            },      
            {
              title: 'Productos',
              content: <SelectProducto header={header} carrito={carrito} productosCargados={productosCargados} setCarrito={setCarrito}></SelectProducto>,
              percent: 40,
              subTitle:'',
              icon:<ShopOutlined />,
              style:{display:stepVisibility.productos},
            },
            {
                title: 'Carrito '  ,
                content: <Carrito carrito={carrito} setCarrito={setCarrito} setProductosCargados={setProductosCargados}></Carrito>,
                percent: 60,
                subTitle: <Badge count={carrito.length} style={{ backgroundColor: '#EE6352' }} />,
                icon:<ShoppingCartOutlined />,
                style:{display:stepVisibility.carrito},
            },
            {
              title: 'Datos Adicionales',
              content: <DatosAdicionales header={header} datosOpcionales={datosOpcionales} setDatosOpcionales={setDatosOpcionales} adjuntos={adjuntos} setAdjuntos={setAdjuntos}> </DatosAdicionales>,
              percent: 80,
              subTitle:'',
              icon:<SolutionOutlined />,
              style:{display:stepVisibility.datos},
        
            },
            {
                title: 'Confirmación',
                content: <Confirmacion header={header} carrito={carrito} datosOpcionales={datosOpcionales} 
                         setHeader={setHeader} setCarrito={setCarrito} setDatosOpcionales={setDatosOpcionales}></Confirmacion>,
                percent: 99.9,
                icon:<SafetyOutlined />,
                style:{display:stepVisibility.confirmacion},
            },          
    ];

    const openNotification = () => {
        notification.open({
          message: 'Información',
          description:
            'Su saldo actual es: ' + cookies.get('saldo'),
          icon:<InfoCircleOutlined />
        });
      };

    const getSaldo = async (clave) => {
        //console.log(loginData.correo);
        //console.log(baseURL);
        await axios
          .get(urlGetSaldo, {
            params: { clave: clave },
          })
          .then((response) => {
            //console.log( response.data);
            if(response.data[0].saldo == null){
                cookies.set('saldo',0,{path:'/'});
            }else{
                cookies.set('saldo',response.data[0].saldo,{path:'/'});
            }            
          })
          .catch((error) => {
            console.log("error" + error.response);
            //mensajeError();
          });
      };
      
    function anchoPantalla(){
       //console.log("pantalla");
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({
                cliente:'inline',
                productos:'none',
                carrito:'none',
                datos:'none',
                confirmacion:'none'});
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }
    }


const next = () => {
    
    if (current === 0) {

        if ( header.cliente.length <= 2 ) {            
            errorClientes();            
        }else{

            //if(header.saldoCliente > 0) {
                //errorSaldo();
            //}else{
                console.log('Cliente '+ header.cliente.length);

                if (width <= 855) {
                    //console.log("menor");
                    setStepVisibility({   
                        cliente:'none',
                        productos:'inline',
                        carrito:'none',
                        datos:'none',
                        confirmacion:'none',
                        });
                }else{         
                    //console.log("mayor");
                    setStepVisibility({   
                    cliente:'inline',
                    productos:'inline',
                    carrito:'inline',
                    datos:'inline',
                    confirmacion:'inline',
                    });
                }
                
                setCurrent(current + 1); 
                
            //}
            
        }
    }

    if (current === 1) {
        if (carrito.length > 0) {
            console.log('Productos');

            if (width <= 855) {
                //console.log("menor");
                setStepVisibility({   
                    cliente:'none',
                    productos:'none',
                    carrito:'inline',
                    datos:'none',
                    confirmacion:'none',
                    });
            }else{         
                //console.log("mayor");
                setStepVisibility({   
                cliente:'inline',
                productos:'inline',
                carrito:'inline',
                datos:'inline',
                confirmacion:'inline',
                });
            }

            
            setCurrent(current + 1); 
            
        }else{
            errorPedido();   
        }
        
    }

    if (current === 2) {
        if (carrito.length > 0) {
            console.log('Carrito');

            if (width <= 855) {
                //console.log("menor");
                setStepVisibility({   
                    cliente:'none',
                    productos:'none',
                    carrito:'none',
                    datos:'inline',
                    confirmacion:'none',
                    });
            }else{         
                //console.log("mayor");
                setStepVisibility({   
                cliente:'inline',
                productos:'inline',
                carrito:'inline',
                datos:'inline',
                confirmacion:'inline',
                });
            }

            

            setCurrent(current + 1); 

        }else{
            errorPedido();   
        }
    }

    if (current === 3) {   
        //console.log('Datos');
        if (datosOpcionales.formaPago.length > 1 && datosOpcionales.formaEnvio.length > 1) {
            if (width <= 855) {
                //console.log("menor");
                setStepVisibility({   
                    cliente:'none',
                    productos:'none',
                    carrito:'none',
                    datos:'none',
                    confirmacion:'inline',
                    });
            }else{         
                //console.log("mayor");
                setStepVisibility({   
                cliente:'inline',
                productos:'inline',
                carrito:'inline',
                datos:'inline',
                confirmacion:'inline',
                });
            }

            setCurrent(current + 1);   
        }else{
            errorDatos();
        }
        
        
              
    }

    if (current === 4) {     
        //console.log('Confirmacion');  
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'none',
                productos:'none',
                carrito:'none',
                datos:'none',
                confirmacion:'inline',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }
        
        setCurrent(current + 1);         
    }
             
};

const prev = () => {
    
    if (current === 1) {
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'inline',
                productos:'none',
                carrito:'none',
                datos:'none',
                confirmacion:'none',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }            
        setCurrent(current - 1);            
    }

    if (current === 2) {
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'none',
                productos:'inline',
                carrito:'none',
                datos:'none',
                confirmacion:'none',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }            
        setCurrent(current - 1);            
    }

    if (current === 3) {
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'none',
                productos:'none',
                carrito:'inline',
                datos:'none',
                confirmacion:'none',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }            
        setCurrent(current - 1);            
    }

    if (current === 4) {
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'none',
                productos:'none',
                carrito:'none',
                datos:'inline',
                confirmacion:'none',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }            
        setCurrent(current - 1);            
    }

    if (current === 5) {
        if (width <= 855) {
            //console.log("menor");
            setStepVisibility({   
                cliente:'none',
                productos:'none',
                carrito:'none',
                datos:'none',
                confirmacion:'inline',
                });
        }else{         
            //console.log("mayor");
            setStepVisibility({   
            cliente:'inline',
            productos:'inline',
            carrito:'inline',
            datos:'inline',
            confirmacion:'inline',
            });
        }            
        setCurrent(current - 1);            
    }
        
    
};

function errorClientes() {
    Modal.warning({
      title: 'Datos Vacios 😅',
      content: 'Por favor selecciona un cliente y sucursal valido',
    });
  } 
  
  function errorSaldo() {
    Modal.warning({
      title: 'Saldo Negativo 😅',
      content: 'El cliente cuenta con un saldo deudor, En caso de duda, revisar con el área de crédito y cobranza.',
    });
  }

  function errorPedido() {
    Modal.warning({
      title: 'Carrito Vacío 😬',
      content: 'Por favor agrega un producto para continuar con el pedido',
    });
  } 

  function errorDatos() {
    Modal.warning({
      title: 'Datos Incompletos 😥',
      content: 'Por favor verifica que los campos metodo de envío o forma de pago no esten vacíos',
    });
  } 

  function mensajeFinal(){
    Modal.success({
        title: 'Pedido Finalizado 🤝',
        content: 'En breve, recibiras un mail con los detalles de tu pedido. \nEl pedido se transmitirá en 10 minutos aproximadamente, por tanto si deseas cancelarlo aún puedes hacerlo. \nGracias.',
    });      
  }

  function mensajeCredito(){
    Modal.info({
        title: 'Límite de Crédito Excedido 😵‍💫',
        content: 'El pedido será aprobado por el área de crédito, en caso de ser favorable, se surtirá su orden.\nGracias.',
    });      
  }

  function mensajeBloqueo(){
    Modal.info({
        title: 'Límite de Crédito Excedido 😵‍💫',
        content: 'Lo sentimos, de momento no se puede realizar un nuevo pedido, hasta regularizar su saldo.\nAnte cualquier duda y/o malentendido, quedamos de usted.',
    });      
  }

  async function uploadAttachments(folioVenta){
    const uploadPromises =
    adjuntos.forEach(async (item) => {
        var f = new FormData();
        f.append('folio', folioVenta);
        f.append('nombre', item.nombre);
        f.append("METHOD", 'POST');
        
        await axios.post(urlAdjuntos,f)
        .then(response=>{        
            console.log(response.data);
        //setState(false);
        //window.location.href = "./registro_finalizado";
        }).catch(error=>{
        console.log(error);
        });

    const uploadedResults = await Promise.all(uploadPromises);
    return uploadedResults;

    });
  }

  async function createOrder_AM(folioVenta){
    try{
        var montoTotalAutoMagic = 0;
        var regAM = 0;
        const orderItemsPromises =
        carrito.forEach(async (item) => {
            //console.log(item);
            if(item.empresa == 'ADGOA'){
                regAM = 1;
                var f = new FormData();
                f.append('folio', folioVenta);
                f.append('clave_producto', item.clave);
                f.append('cantidad', item.cantidad);
                f.append('precio', item.precio);
                f.append('impuesto1', 0);
                f.append('impuesto4', item.impuesto4);
                f.append('descuento', item.descuento);
                f.append('subtotal', item.subtotal);
                f.append('total', item.total);
                f.append('empresa', item.empresa);
                f.append("METHOD", 'POST');
                
                montoTotalAutoMagic += parseFloat(item.total);
                await axios.post(urlPedidos1,f)
                .then(response=>{
                //console.log(response.data);
                //setState(false);
                //window.location.href = "./registro_finalizado";
                }).catch(error=>{
                console.log(error);
                })
            }
        });      
    
        
        if(regAM == 1 ){
            var totDescuento = carrito.reduce((sum, producto) => sum + producto.descuento, 0);
            var obs = "[Pago] " + datosOpcionales.formaPago + " [Envío] " + datosOpcionales.formaEnvio + " [Observaciones] "+ datosOpcionales.observaciones;
            
            var f = new FormData();
            f.append('folio', folioVenta);
            f.append('clave_vendedor', cookies.get('clave'));
            f.append('total', montoTotalAutoMagic);
            f.append('descuento', totDescuento);
            f.append('clave_cliente', header.matriz);
            f.append('observaciones', obs);
            f.append('paqueteria', datosOpcionales.paqueteria);
            f.append('noguia', datosOpcionales.noguia);
            f.append('estatus', header.tipo);
            f.append('oc', datosOpcionales.oc);
            f.append('condicion', datosOpcionales.condicion);
            f.append('empresa','ADGOA');
            f.append('nombre_vendedor',cookies.get('nombre'));
            f.append('mail_vendedor',cookies.get('correo'));
            f.append("METHOD", 'POST');
        
            const orderHeaderPromises =
            await axios.post(urlPedidos2,f)
            .then(response=>{
                console.log(response.data);
                //setState(false);
                //mensajeFinal();
                //updateSaldo();
                //window.location.href = "./pedidos";
            }).catch(error=>{
                console.log(error);
            });

            //const results = await Promise.all(orderItemsPromises, orderHeaderPromises);
            //return results;
        }
    }catch(error){
        console.log(error);
    }
  }

  async function createOrder_OHB(folioVenta){
    var montoTotalOHB = 0;
    var regOHB = 0;
    const orderItemsPromises =
    carrito.forEach(async(item) => {
        //console.log(item);
        if(item.empresa == 'OHB'){
            var f = new FormData();
            regOHB = 1;
            f.append('folio', folioVenta);
            f.append('clave_producto', item.clave);
            f.append('cantidad', item.cantidad);
            f.append('precio', item.precio);
            f.append('impuesto1', 0);
            f.append('impuesto4', item.impuesto4);
            f.append('descuento', item.descuento);
            f.append('subtotal', item.subtotal);
            f.append('total', item.total);
            f.append('empresa', item.empresa);
            f.append("METHOD", 'POST');
            
            montoTotalOHB += parseFloat(item.total);
            await axios.post(urlPedidos1,f)
            .then(response=>{
            //console.log(response.data);
            //setState(false);
            //window.location.href = "./registro_finalizado";
            }).catch(error=>{
            console.log(error);
            })
        }
    });

    if(regOHB == 1){
        const totDescuento = carrito.reduce((sum, producto) => sum + producto.descuento, 0);
        var obs = "[Pago] " + datosOpcionales.formaPago + " [Envío] " + datosOpcionales.formaEnvio + " [Observaciones] "+ datosOpcionales.observaciones;
    
        var f = new FormData();
        f.append('folio', folioVenta);
        f.append('clave_vendedor', cookies.get('clave'));
        f.append('total', montoTotalOHB);
        f.append('descuento', totDescuento);
        f.append('clave_cliente', header.matriz);
        f.append('observaciones', obs);
        f.append('paqueteria', datosOpcionales.paqueteria);
        f.append('noguia', datosOpcionales.noguia);
        f.append('estatus', header.tipo);
        f.append('oc', datosOpcionales.oc);
        f.append('condicion', datosOpcionales.condicion);
        f.append('empresa','OHB');
        f.append('nombre_vendedor',cookies.get('nombre'));
        f.append('mail_vendedor',cookies.get('correo'));
        f.append("METHOD", 'POST');
    
        const orderHeaderPromises =
        await axios.post(urlPedidos2,f)
        .then(response=>{
            console.log(response.data);
            //setState(false);
            //mensajeFinal();
            //updateSaldo();
            //window.location.href = "./pedidos";
        }).catch(error=>{
            console.log(error);
        })

        //const results = await Promise.all(orderItemsPromises, orderHeaderPromises);
        //return results;
    }
  }


  async function registrarPedido(){
    
    //validamos el total del pedido y lo sumamos al saldo para validar el crédito
    const totTotal = carrito.reduce((sum, producto) => sum + producto.total, 0);
    const credito = cookies.get('credito');
    const saldo = cookies.get('saldo');
    const nuevoSaldo = parseFloat(totTotal) + parseFloat(saldo);

    try{

        const [attachmentsResponse, amResponse, ohbResponse] = await Promise.all([
            uploadAttachments(folio.folioVenta),
            createOrder_AM(folio.folioVenta),
            createOrder_OHB(folio.folioVenta)
          ]);

        console.log('Attachments uploaded:', attachmentsResponse);
        console.log('ADGOA items:', amResponse);
        console.log('OHB items:', ohbResponse);
        
        window.location.href = "./pedidos";  

    }catch(error){
        console.error('Error sending requests:', error);
    }
  }

  const finalizarPedido = async()=>{
      if (header.tipo == "Pedido") {
        confirm({
            title: '¿Deseas Finalizar la Captura del Pedido? 📦',
            icon: <QuestionCircleTwoTone />,
            content: <span>Anotaciones:<br />Una vez capturado, recibiras un mail con los detalles de tu pedido. <br />El pedido se transmitirá en 10 minutos aproximadamente, por tanto si deseas cancelarlo aún puedes hacerlo. <br /><br />Gracias.</span>,
            okText:'Guardar',
            cancelText:'Cancelar',
            onOk() {
              setState({loading:false});
                axios.get(urlFolio)
                .then(response=>{
                    console.log(response.data);
                    setFolio({
                        folioVenta: response.data[0].folio,
                    });
                }).catch(error=>{
                    console.log(error);
                })
                setState({loading:true});
            },
            onCancel() {
              console.log('Cancel');
            },
        });
      }

      if (header.tipo == "Cotizacion") {
        confirm({
            title: '¿Deseas Finalizar la Captura de la Cotización? 📑',
            icon: <QuestionCircleTwoTone />,
            content: <span>Anotaciones:<br />Una vez capturado, recibiras un mail con los detalles de la cotización. <br />La cotización NO SE TRANSMITIRA AL SISTEMA, a menos que se solicite como PEDIDO.<br /><br /></span>,
            okText:'Guardar',
            cancelText:'Cancelar',
            onOk() {
              setState({loading:false});
                axios.get(urlFolio)
                .then(response=>{
                    console.log(response.data);
                    setFolio({
                        folioVenta: response.data[0].folio,
                    });
                }).catch(error=>{
                    console.log(error);
                })
                setState({loading:true});
            },
            onCancel() {
              console.log('Cancel');
            },
        });
      }
    
    //setState(true);
    //getFolio
    
    }

    function verificaSaldo(){
        const credito = cookies.get('credito');
        const saldo = cookies.get('saldo');
        if(saldo < credito){
            //|
        }else{
            mensajeBloqueo();
            window.location.href = "/menu_principal";
        }
      } 

      const getDescuentoMatriz = async()=>{
        await axios.get(baseURL_ClientesDescuento, {
            params: {
            matriz: header.cliente,
            }
        })
        .then(response=>{
            //console.log(matriz2);
            //if(response.data[0].descuento.length > 0){
                //console.log('DESCUENTO ' + response.data[0].descuento);
                setHeader({
                    tipo:header.tipo,
                    matriz: header.matriz,
                    marca: header.marca,
                    descuento: response.data[0].descuento,
                    cliente:header.cliente,
                    diascredito:response.data[0].dias_credito,
                    saldoCliente:header.saldoCliente,
                })
            //}
            
            
        }).catch(error=>{
            console.log(error);
        })
    }


    useEffect(() => {        
        anchoPantalla();
        //updateSaldo();
        //openNotification();
        //verificaSaldo();
    },[])

  useEffect(()=>{
    //console.log('Items ' +carrito.length);
  },[carrito]);

  useEffect(() => {
    //console.log('GRAL');
    //console.log(header.cliente);
    
    if(header.cliente.length > 0 ){
        getDescuentoMatriz()
    }
  }, [header])

  useEffect(() => {
     if (folio.folioVenta.length > 2) {
         registrarPedido();
     }
  }, [folio])

    return (
        <Fragment>
            <Spin spinning={state.loading} size="large" tip="Guardando pedido...">
            
            <Layout style={{ height: "100vh", overflow: "auto", backgroundColor:"#ffffff" }}>    
            <VendedorMenu></VendedorMenu>                    
            <Content style={{ 
                overflow: 'auto', overflowX:'hidden' }}
                className="content">
                <br />
                <center>                
                <Row gutter={[16]} type="flex">
                    <Col xs={1} sm={1} md={3} lg={1}> </Col>
                    <Col xs={22} sm={22} md={18} lg={22}>
                    <div className="steps-content">
                        {steps[current].content}
                    </div>
                    </Col>
                    <Col xs={1} sm={1} md={3} lg={1}> </Col>
                </Row>                
                </center>

                <FloatButton
                icon={<DollarOutlined />}
                type='primary'
                onClick={openNotification}
                />    
            </Content> 
                   
            <Footer className="steps-action" style={{ 
                backgroundColor:"#ffffff",                 
                 position: 'relative',
                    left: 0,
                    bottom: 0,
                    width: '100%'
                 }}>
            <center>
                <div >
                <Steps type="navigation" 
                    size="small" responsive={true} current={current}>
                    {steps.map(item => (
                    <Step key={item.title} title={item.title} subTitle={item.subTitle} icon={item.icon} style={item.style}></Step>
                    ))}                
                </Steps>    
                <br />                    
                        {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Siguiente
                        </Button>
                        )}
                        {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => finalizarPedido()}>
                            Finalizar
                        </Button>
                        )}
                        {current > 0 && (
                        <Button onClick={() => prev()}>
                            Regresar
                        </Button>
                        )}
                    </div>
            </center>
            </Footer>
            </Layout>
            </Spin>
        </Fragment>
    )
}

export default Pedidonuevo
