import React,{Fragment,useEffect} from 'react';
import VendedorMenu from './VendedorMenu';
import { Card } from 'antd';
import { TagsTwoTone,FundTwoTone,PlusCircleTwoTone,ShopTwoTone,DollarTwoTone,ShoppingTwoTone,ContactsTwoTone,SmileTwoTone,ReconciliationTwoTone } from '@ant-design/icons';
import { Row,Col,Divider,Typography, Modal  } from 'antd';
import { Layout } from 'antd';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import '../css/StyleMenu.css';
const cookies = new Cookies();

const { Footer, Content } = Layout;
const { Title } = Typography;


const urlGetSaldo = process.env.REACT_APP_URL_API+"/API_getSaldo.php";

function VendedorBody() {

    const updateSaldo = async () => {
    //console.log(loginData.correo);
    //console.log(baseURL);
        await axios
            .get(urlGetSaldo, {
            params: { clave: cookies.get('clave') },
            })
            .then((response) => {
            console.log( response.data);
            if(response.data[0].saldo == null){
                cookies.set('saldo',0,{path:'/'});
            }else{
                cookies.set('saldo',response.data[0].saldo,{path:'/'});
            }
            })
            .catch((error) => {
            console.log("error" + error.response);
            //mensajeError();
            });
    };


    function newPedido(){
        const credito = cookies.get('credito');
        const saldo = cookies.get('saldo');
        //if(saldo < credito){
            window.location.href = "/nuevo_pedido";
        //}else{
        //    mensajeBloqueo();
        //}
    }
    function pedidos(){
        window.location.href = "/pedidos";
    }

    /*
    function inventarios(){
        window.location.href = "/inventarios";
    }    
    /*function downloadPrecios(){
        window.location.href = "/precios";
    }
    function promociones(){
        window.location.href = "/promociones";
    }
    function estadistica(){
        window.location.href = "/estadisticaVentas";
    }
    function catalogoProductos(){
        window.location.href = process.env.REACT_APP_URL_CONTAINER +"/Catalogo_AutoMagic_2022.pdf";
    }
    function downloadPrecios(){
        window.location.href = process.env.REACT_APP_URL_CONTAINER +"/Precios_2022.pdf";
    }
    function clientes(){
        window.location.href = "/clientes";
    }
    function prospeccion(){
        window.location.href = "/prospeccion_clientes";
    }*/

    function mensajeBloqueo(){
        Modal.info({
            title: 'Límite de Crédito Excedido 😵‍💫',
            content: 'Lo sentimos, de momento no se puede realizar un nuevo pedido, hasta regularizar su saldo.\nAnte cualquier duda y/o malentendido, quedamos de usted.',
        });      
      }

    useEffect(() => {       
        updateSaldo();
    },[])

    return (
        <div>
            
            <Fragment>   
                <Layout style={{ height: "100vh", overflow: "auto", backgroundColor:"#e5e5e5" }}>
                <VendedorMenu />
                <Content style={{ 
                overflow: 'auto',
                paddingLeft:"5%",
                paddingRight:"5%",}}>
                    <br />
                    <center> 
                        <Row>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        <Col xs={20} sm={20} md={20} lg={20}><Title level={4}>BIENVENIDO {cookies.get('nombre')}</Title></Col>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        </Row>              
                                        
                    <br />
                    <br />

                    <Divider orientation="left">Pedidos</Divider>
                    <div className="slider">
                        <Card className="cardP" hoverable bordered={true} onClick={() => newPedido()} style={{backgroundColor:'#ffffff'}}>
                        <PlusCircleTwoTone twoToneColor="#ef233c" style={{ fontSize: '300%'}} />
                        <br />                            
                        <Divider>Nuevo Pedido</Divider>
                        </Card>          
                        
                        <Card className="cardP" hoverable bordered={true} onClick={() => pedidos()}>
                            <ShopTwoTone twoToneColor="#ef233c" style={{ fontSize: '300%'}} />
                            <br />                            
                            <Divider>Pedidos</Divider>
                        </Card>
                        
                    </div>

                

                    
                    
                                 
                    </center>
                    
                </Content>            
                <Footer style={{ 
                backgroundColor:"#ffffff",                 
                 position: 'relative',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                 }}>
                <center>
                <div >
                    <h6>AutoMagic 4 Sellers powered by MATIC</h6>
                </div>
                </center>
                </Footer>
                </Layout>
            </Fragment>
        </div>
    )
}

export default VendedorBody
