import React,{useEffect,useState} from 'react'
import { Typography,Input, Select, Row,Col,Upload, Modal } from 'antd';
import { InboxOutlined,CloseOutlined, UserOutlined, EditOutlined,LoginOutlined, EllipsisOutlined, SettingOutlined, 
    FolderOpenOutlined,SyncOutlined,LikeOutlined,CloseCircleOutlined,ClockCircleOutlined,
    FireOutlined,MinusOutlined,UserSwitchOutlined,CoffeeOutlined} from '@ant-design/icons';
import AWS from 'aws-sdk'


function DatosAdicionales({header,datosOpcionales, setDatosOpcionales, adjuntos, setAdjuntos}) {
    const { Title } = Typography;
    const { TextArea } = Input;
    const {Option} = Select;
    const { Dragger } = Upload;
    const [opcionesCredito,setOpcionesCredito] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const [preadjuntos,setPreadjuntos] = useState({
      nombre:'',
  });

    const props = {
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials
          })
          {
            var S3 = new AWS.S3({
                accessKeyId:  process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ACCESS_KEY,
              });
            //const S3 = new AWS.S3();
            console.log(file);
            console.log("DEBUG filename", file.name);
            console.log("DEBUG file type", file.type);
        
            const objParams = {
              Bucket: process.env.REACT_APP_AWS_ACCESS_BUCKET_NAME,
              Key: "pedidos-automagic/" + file.name,
              Body: file,
              ContentType: file.type // TODO: You should set content-type because AWS SDK will not automatically set file MIME
            };

            S3.putObject(objParams)
              .on("httpUploadProgress", function({ loaded, total }) {
                onProgress(
                  {
                    percent: Math.round((loaded / total) * 100)
                  },
                  file
                );
              })
              .send(function(err, data) {
                if (err) {
                  onError();
                  console.log("Something went wrong");
                  console.log(err.code);
                  console.log(err.message);
                } else {
                  onSuccess(data.response, file);
                  console.log("SEND FINISHED");
                  console.log(file);
                  console.log(file.name);

                  
                    setPreadjuntos({
                      nombre:file.name,
                    });
                  

                  
                }
              });
          }  
      };

      function changeCredito(value){
          setDatosOpcionales({
              formaPago: value,
              formaEnvio:datosOpcionales.formaEnvio,
              observaciones:datosOpcionales.observaciones,
          });
      }

      function changeEnvio(value){
          console.log(value);

          if(value == "Dropshipping"){
            showModal();
          }

        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:value,
            observaciones:datosOpcionales.observaciones,
            paqueteria:datosOpcionales.paqueteria,
            noguia:datosOpcionales.noguia,
            oc:datosOpcionales.oc,
            condicion:datosOpcionales.condicion,
        });
      }

      function changeFile(value){
          console.log(value);      
      }

      function onRemove(item){
        const adjuntosRestantes = adjuntos.filter( preadjuntos => preadjuntos.nombre !== item.name);        
        setAdjuntos(adjuntosRestantes);   
      }


      const changeObservaciones = e => {
        console.log(e.target.value);
        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:datosOpcionales.formaEnvio,
            observaciones:e.target.value,
            paqueteria:datosOpcionales.paqueteria,
            noguia:datosOpcionales.noguia,
            oc:datosOpcionales.oc,
            condicion:datosOpcionales.condicion,
        });
        //console.log('Change:', e.target.value);
      };

      const changeGuia = e => {
        console.log(e.target.value);
        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:datosOpcionales.formaEnvio,
            observaciones:datosOpcionales.observaciones,
            paqueteria:datosOpcionales.paqueteria,
            noguia:e.target.value,
            oc:datosOpcionales.oc,
            condicion:datosOpcionales.condicion,
        });
        //console.log('Change:', e.target.value);
      };

      const changePaqueteria = e => {
        console.log(e.target.value);
        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:datosOpcionales.formaEnvio,
            observaciones:datosOpcionales.observaciones,
            paqueteria:e.target.value,
            noguia:datosOpcionales.noguia,
            oc:datosOpcionales.oc,
            condicion:datosOpcionales.condicion,
        });
        //console.log('Change:', e.target.value);
      };

      const changeOC = e => {
        console.log(e.target.value);

        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:datosOpcionales.formaEnvio,
            observaciones:datosOpcionales.observaciones,
            paqueteria:datosOpcionales.paqueteria,
            noguia:datosOpcionales.noguia,
            oc:e.target.value,
            condicion:datosOpcionales.condicion,
        });
        //console.log('Change:', e.target.value);
      };

      const changeCondicion = e => {
        console.log(e.target.value);

        setDatosOpcionales({
            formaPago: datosOpcionales.formaPago,
            formaEnvio:datosOpcionales.formaEnvio,
            observaciones:datosOpcionales.observaciones,
            paqueteria:datosOpcionales.paqueteria,
            noguia:datosOpcionales.noguia,
            oc:datosOpcionales.oc,
            condicion:e.target.value,
        });
        //console.log('Change:', e.target.value);
      };

      useEffect(() => {
        setDatosOpcionales({
            formaPago: 'Credito',
            formaEnvio:'Domicilio',
            observaciones:'',
            paqueteria:'',
            noguia:'',
            oc:'',
            condicion:'',
        });

        if (header.diascredito>0) {
            setOpcionesCredito(true);
        }else{
            setOpcionesCredito(false);
        }

      }, [])

      useEffect(()=>{
        if (preadjuntos.nombre.length > 2) {
            console.log(preadjuntos);

            if (adjuntos.length === 0) 
            {
                setAdjuntos([
                    preadjuntos
                ]);
              }else{
                setAdjuntos([
                    ...adjuntos,
                    preadjuntos
                ]);
            }
        }
    },[preadjuntos]);

    return (
        <div>
            <center>
                <Modal title="Observaciones Dropshipping" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <p>(Dimensiones Máximas 120x20x20)</p>
                </Modal>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} ><Title level={4}>Datos adicionales</Title></Col>
                </Row>
                <br />    
                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >                
                <Input placeholder="Orden de Compra" maxLength={40} onChange={changeOC}/>
                <br></br>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br></br> 
                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >                
                <Input placeholder="Paqueteria" maxLength={250} onChange={changeCondicion} value={"Paqueteria"}/>
                <br></br>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br></br>            
                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >
                Forma de Envío
                <br></br>
                <Select defaultValue="Domicilio" style={{width:'100%'}} allowClear onChange={changeEnvio}>
                    <Option value="Dropshipping">Dropshipping</Option>
                    <Option value="Domicilio">Envío a Domicilio</Option>
                </Select>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br></br>

                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >                
                <Input placeholder="Paqueteria" maxLength={40} onChange={changePaqueteria}/>
                <br></br>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br></br>
                
                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >
                <Input placeholder="No. Guia"  maxLength={20} onChange={changeGuia} />
                <br></br>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br></br>
                
                <Row>
                    <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                    <Col xs={20} sm={20} md={12} lg={12} xl={12}>
                    Observaciones
                    <br></br>
                    <TextArea showCount maxLength={254} rows={4} allowClear onChange={changeObservaciones} />
                    </Col>
                    <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                <br /> 
                <Row>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>                
                <br />
                <Col xs={20} sm={20} md={12} lg={12} xl={12} >
                Archivos Adjuntos
                    <Dragger {...props} onChange={changeFile} onRemove={onRemove}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Da clic o arrastra los archivos a subir</p>
                        <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                        </p>
                    </Dragger>
                </Col>
                <Col xs={2} sm={2} md={6} lg={6} xl={6} ></Col>
                </Row>
                
            </center>
        </div>
    )
}

export default DatosAdicionales
