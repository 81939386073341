import React,{Fragment,useState,useEffect} from 'react'
import { Row,Col,Layout,Typography,Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import VendedorMenu from "./VendedorMenu";
import Cookies from 'universal-cookie';
import axios from 'axios';
import {useParams} from 'react-router-dom';

function History() {
    const cookies = new Cookies();
    const { Title } = Typography;
    const { Content } = Layout;
    const urlTimeline = process.env.REACT_APP_URL_API+"/getTimelinePedido_Cliente.php";
    const [datos, setDatos] = useState([]);
    const {noPedido} = useParams();
 
    const getTimeline = async()=>{
        await axios.get(urlTimeline, {
            params: {
            folio: noPedido,
            }
        })
        .then(response=>{
            console.log(response);
            setDatos(response.data);
            //setSpinner(false);
        }).catch(error=>{
            //setSpinner(false);
            //errorPedidos()
            console.log(error);
        })
    }

    useEffect(() => {
        getTimeline();
    }, [])


    return (
        <Fragment>
            <Layout
          style={{
            height: "100vh",            
            overflow: "auto",
            backgroundColor: "#f7f7f7",
          }}
        >
          <VendedorMenu></VendedorMenu>
          <br></br>
          <center><Title level={4}>Timeline {noPedido}</Title></center>
          <Content
            style={{
              overflow: "auto",
              paddingLeft:"5%",
              paddingRight:"5%",
            }}
            className="content"
          >
              <br />
              <Timeline mode="alternate">
                  {
                      datos.map((history,i)=>(                          
                        <Timeline.Item>{history.fecha} {history.accion}<br></br> por {history.usuario}</Timeline.Item>
                      ))
                  }
            </Timeline>
          </Content>
        </Layout>            
        </Fragment>
            
        
    )
}

export default History
