import React from "react";
import { LogoutOutlined,UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';


import {  Menu} from 'antd';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const AlmacenMenu = () => {
  
  function cerrarSesion() {
    cookies.remove('clave',{path:'/'});
    cookies.remove('tipo',{path:'/'});
    cookies.remove('nombre',{path:'/'});
    cookies.remove('correo',{path:'/'});
    cookies.remove('empresa',{path:'/'});
    window.location.href = "../../";
}

function MenuPrincipal() {
  window.location.href = "/almacen/menu_principal";
}

const centerStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
}

const rightStyle = {position: 'absolute', top: 0, right: 0, backgroundColor:'#ff1654'}

return (
  <div>
    <Menu theme="dark" mode="horizontal" style={{backgroundColor:'#D1D1D1',              
     position: 'relative',
     left: 0,
     top: 0,
     width: '100%',}}>
        <Menu.Item key={['1']} onClick={()=>MenuPrincipal()} >
          <Avatar shape="square" icon={<UserOutlined />}  style={{backgroundColor:'#ff1654'}} />
        </Menu.Item>
    </Menu>
    <Menu theme="dark" mode="horizontal" style={rightStyle}>
      <Menu.Item key={['2']} onClick={()=>cerrarSesion()} icon={<LogoutOutlined />}>
      
      </Menu.Item>        
    </Menu>


  </div>
)
};

export default AlmacenMenu;