import React,{Fragment} from 'react'
import AdminMenu from './VendedorMenu';
import { Card } from 'antd';
import { FundTwoTone,PlusCircleTwoTone,GoldTwoTone,DollarCircleTwoTone,ShoppingTwoTone,ContactsTwoTone,SmileTwoTone,ReconciliationTwoTone } from '@ant-design/icons';
import { Row,Col,Divider,Typography  } from 'antd';
import { Layout } from 'antd';
import Cookies from 'universal-cookie';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import '../../css/StyleMenu.css';
const cookies = new Cookies();
const { Footer, Content } = Layout;
const { Title } = Typography;



function AdminBody() {

    
    function pedidos(){
        window.location.href = "/vendedor/pedidos";
    }

    function newPedido(){
        window.location.href = "/vendedor/nuevo_pedido";
    }
    

    return (
        <div>
            
            <Fragment>   
                <Layout style={{ height: "100vh", overflow: "auto", backgroundColor:"#ffffff" }}>
                <AdminMenu />
                <Content style={{ 
                overflow: 'auto',
                paddingLeft:"5%",
                paddingRight:"5%",}}>
                    <br />
                    <center> 
                        <Row>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        <Col xs={20} sm={20} md={20} lg={20}><Title level={4}>Vendedor {cookies.get('nombre')}</Title></Col>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        </Row>              
                                        
                    <br />
                    <br />

                    <Divider orientation="left">Pedidos Realizados</Divider>
                    <div className="slider">
                        <Card className="cardP" hoverable bordered={true} onClick={() => newPedido()} style={{backgroundColor:'#ffffff'}}>
                            <PlusCircleTwoTone twoToneColor="#3a86ff" style={{ fontSize: '300%'}} />
                            <br />                            
                            <Divider>Nuevo Pedido</Divider>
                        </Card>          
                        
                        <Card className="cardP" hoverable bordered={true} onClick={() => pedidos()}>
                            <ShoppingTwoTone twoToneColor="#00c49a" style={{ fontSize: '300%'}} />
                            <br />                            
                            <Divider>Listado</Divider>
                        </Card>   

                    </div>

                    </center>
                    
                </Content>            
                <Footer style={{ 
                backgroundColor:"#ffffff",                 
                 position: 'relative',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                 }}>
                <center>
                <div >
                    <h6>Automagic 4 Sellers powered by MATIC</h6>
                </div>
                </center>
                </Footer>
                </Layout>
            </Fragment>
        </div>
    )
}

export default AdminBody
