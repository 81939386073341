import React,{useEffect,useState} from 'react'
import { Select,Typography,Row,Col,Spin } from 'antd';
import Cookies from 'universal-cookie';
import axios from 'axios';

function Clientes({header,setHeader}) {

    const cookies = new Cookies();
    const baseURL = process.env.REACT_APP_URL_API+"/getMatrizClientes_All.php";
    const baseURL_Clientes = process.env.REACT_APP_URL_API+"/getClientes.php";
    const baseURL_ClientesDescuento = process.env.REACT_APP_URL_API+"/getDescuentoMatriz.php";
    const urlGetSaldo = process.env.REACT_APP_URL_API+"/API_getSaldo_Vendedor.php";
    
    const { Option } = Select;
    const { Title } = Typography;
    const [matrizResponse,setMatrizResponse] = useState([]);
    const [matriz,setMatriz] = useState([]);
    const [matriz2,setMatriz2] = useState([]);
    const [clientes,setClientes] = useState([]);
    const [spinner,setSpinner] = useState(true);

    const getSaldo = async () => {
        //console.log(loginData.correo);
        console.log(matriz2);
        await axios
          .get(urlGetSaldo, {
            params: { clave: matriz2 },
          })
          .then((response) => {
            
            if(response.data[0].saldo == null){
                cookies.set('saldo',0,{path:'/'});
            }else{
                cookies.set('saldo',response.data[0].saldo,{path:'/'});
            }

            setHeader({
                tipo:header.tipo,
                matriz: header.matriz,
                marca: header.marca,
                descuento: header.descuento,
                cliente: header.cliente,
                diascredito:header.diascredito,
                saldoCliente:response.data[0].saldo,
            })    
            console.log(response.data[0].saldo);       
          })
          .catch((error) => {
            console.log("error" + error.response);
            //mensajeError();
          });
      };

    const getMatriz = async()=>{
        await axios.get(baseURL, {
            params: {
            vendedor: cookies.get('clave')
            }
        })
        .then(response=>{            
            setMatrizResponse(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const getDescuentoMatriz = async()=>{
        console.log('MATRIZ ' + matriz2);
        await axios.get(baseURL_ClientesDescuento, {
            params: {
            matriz: matriz2,
            }
        })
        .then(response=>{
            console.log(matriz2);
            //if(response.data[0].descuento.length > 0){
                console.log('DESCUENTO ' + response.data[0].descuento);
                setHeader({
                    tipo:header.tipo,
                    matriz: header.matriz,
                    marca: header.marca,
                    descuento: response.data[0].descuento,
                    cliente:'0',
                    diascredito:response.data[0].dias_credito,
                    saldoCliente:header.saldoCliente,
                })
            //}
            
            
        }).catch(error=>{
            console.log(error);
        })
    }

    const getDescuentoMatriz2 = async()=>{
        await axios.get(baseURL_ClientesDescuento, {
            params: {
            matriz: matriz2,
            }
        })
        .then(response=>{
            console.log(response.data);
            console.log('DESCUENTO ' + response.data[0].descuento);
            setHeader({
                tipo:header.tipo,
                matriz: header.matriz,
                marca: header.marca,
                descuento: response.data[0].descuento,
                cliente:header.cliente,
                diascredito:response.data[0].dias_credito,
                saldoCliente:header.saldoCliente,
            })
            
        }).catch(error=>{
            console.log(error);
        })
    }

    

    
    
    const getClientes = async()=>{
        await axios.get(baseURL_Clientes, {
            params: {
            clave: matriz2
            }
        })
        .then(response=>{
            //console.log('rClientes ' + response.data);
            setClientes(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    function changeMarca(value){        
        setHeader({
            marca:value,
            tipo:header.tipo,
            matriz: header.matriz,
            cliente: header.cliente,
            descuento:header.descuento,
            diascredito:header.diascredito,
            saldoCliente: header.saldo,
        });        
        //getDescuentoMatriz(value);
        //setSecondCity(cityData[value][0]);
        //console.log("MATRIZ :" + header);
    }

    function changeTipoOperacion(value){        
        setHeader({
            tipo:value,            
            matriz: header.matriz,
            marca: header.marca,
            cliente: header.cliente,
            descuento:header.descuento,
            diascredito:header.diascredito,
        });        
        //getDescuentoMatriz(value);
        //setSecondCity(cityData[value][0]);
        //console.log("MATRIZ :" + header);
    }

    function changeMatriz(value){        
        setMatriz2(value);   
        setHeader({
            tipo:header.tipo,
            matriz: value,
            marca: header.marca,
            cliente: header.cliente,
            descuento:header.descuento,
            diascredito:header.diascredito,
            saldoCliente:header.saldoCliente,
        });        
        //getDescuentoMatriz(value);
        //setSecondCity(cityData[value][0]);
        //console.log("MATRIZ :" + header);
    }

    function changeSucursal(value){
        //setClientes2(value);
        setHeader({
            tipo:header.tipo,
            matriz: matriz2,
            marca: header.marca,
            descuento: header.descuento,
            cliente: value,
            diascredito:header.diascredito,
            saldoCliente:header.saldoCliente,
        });
        //getDescuentoMatriz2(value);
        //getDiasCredito(value);
    }   

    

    useEffect(()=>{        

        if (matriz2.length > 1) {
            getClientes();     
            getDescuentoMatriz(); 
            getSaldo();
            //getDiasCredito();      
          }

      },[matriz2]);

      /*useEffect(() => {
     
        //console.log('DESCUENTO ' +header.descuento);
        //console.log('DIAS ' +header.diascredito);
      }, [header])
*/
      useEffect(()=>{
        if (!cookies.get('correo')) {
            window.location.href = "./";
        }
        getMatriz();

      },[]);

      useEffect(()=>{
            setSpinner(false);
            setMatriz(matrizResponse);
      },[matrizResponse]);

    

    return (
        <div>
        <Spin spinning={spinner} size="large" tip="Cargando Clientes...">
            <center>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Title level={4}>Selecciona el tipo de operación y el cliente</Title>
                </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={2} sm={2} md={2} lg={6} xl={6}>
                    </Col>         
                    <Col xs={20} sm={20} md={20} lg={12} xl={12}>
                    <Select onChange={changeTipoOperacion} placeholder="Operacion" style={{ width: '100%' }} 
                    defaultValue={header.tipo} value={header.tipo}>
                        <Option value="Cotizacion">Cotización</Option>                                      
                        <Option value="Pedido">Pedido</Option>          
                    </Select>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={6} xl={6}>
                    </Col>
                </Row> 
                <br></br>
                
                
                
            </center>
        </Spin>
        </div>
    )
}

export default Clientes
