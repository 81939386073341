import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Card, message } from "antd";
import {
  UserOutlined,LockOutlined
} from '@ant-design/icons';

import axios from "axios";
import "../css/login.css";
import Cookies from "universal-cookie";


export default function Login(props) {
  //const baseURL = "http://localhost/api_pedidos/";
  //const baseURL = "https://18.233.220.89/";
  const baseURL = process.env.REACT_APP_URL_API + "";
  const cookies = new Cookies();

  const [loginData, setloginData] = useState({
    correo: "",
    clave: "",
  });

  const peticionGET = async () => {
    //console.log(loginData.correo);
    //console.log(baseURL);
    if(loginData.correo.length > 2){
      if(loginData.clave.length > 2){

        await axios
        .get(baseURL, {
          params: { correo: loginData.correo, clave: loginData.clave },
        })
        .then((response) => {
          console.log( response);

          if(response.data == false){
            mensajeError();
          }
          cookies.set('clave',response.data.clave,{path:'/'});
          cookies.set('tipo',response.data.tipo,{path:'/'});
          cookies.set('nombre',response.data.nombre,{path:'/'});
          cookies.set('correo',response.data.correo,{path:'/'});
          cookies.set('empresa',response.data.empresa,{path:'/'});
          cookies.set('saldo',response.data.saldo,{path:'/'});
          cookies.set('credito',response.data.credito,{path:'/'});
          //console.log( response.data);
          if (response.data.tipo == "cliente") {
            window.location.href = "./menu_principal";
          }else if(response.data.tipo == "Vendedor"){
            window.location.href = "./vendedor/menu_principal";
          }else if(response.data.tipo == "Almacen"){
            window.location.href = "./almacen/menu_principal";
          }else if(response.data.tipo == "Credito"){
            window.location.href = "./credito/menu_principal";
          }
          
        })
        .catch((error) => {
          console.log("hola" + error.response);
          mensajeError();
        });

      }
    }
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setloginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //const style = { background: '#0092ff', padding: '8px 0' };
  const gridStyle = {
    width: "100%",
    textAlign: "right",
  };
  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };
  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  function mensajeError() {
    message.error(
      "Error al iniciar sesión, por favor verifica tu situación con el Área de Cobranza / Vendedor Tel. 551793 1092"
    );
  }

  return (
    <div className="containerPrincipal">
      <div className="bgImage">

      </div>
      <Row gutter={[16]} className="vertical-center">
        <Col xs={1} sm={1} md={3} lg={6}>
          {" "}
        </Col>
        <Col xs={22} sm={22} md={18} lg={12}>
          <Card title="Auto Magic">
            <Card.Grid style={gridStyle} hoverable={false}>
              <br></br>
              <br></br>
              <Form {...layout}>
                <Form.Item
                  name="Email"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Ingresa un correo valido",
                    },
                  ]}
                >
                  <Input
                    htmlType="Email"
                    name="correo"
                    onChange={handleChange}
                    placeholder="Email"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="Password"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Ingresa tu password",
                    },
                  ]}
                >
                  <Input.Password onChange={handleChange} name="clave" placeholder="Contraseña" prefix={<LockOutlined />} />
                </Form.Item>
                <br></br>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    span={24}
                    size="large"
                    onClick={() => peticionGET()}
                  >
                    Ingresar
                  </Button>
                </Form.Item>
              </Form>
            </Card.Grid>
          </Card>
        </Col>
        <Col xs={1} sm={1} md={3} lg={6}>
          {" "}
        </Col>
      </Row>
    </div>
  );
}
