import React,{useEffect} from 'react'
import { Table, Space,Typography,Button,Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function Carrito({carrito,setCarrito, setProductosCargados}) {
    const { Title } = Typography;
    const columns = [
        {
            title: 'Empresa',
            dataIndex: 'empresa',
            key: 'empresa',
            responsive: ['lg'],
        },
        {
          title: 'Clave',
          dataIndex: 'clave',
          key: 'clave',
          responsive: ['md'],
        },
        {
          title: 'Producto',
          dataIndex: 'descripcion',
          key: 'descripcion',
          responsive: ['xs','md']
        },
        {
          title: 'Cantidad',
          dataIndex: 'cantidad',
          key: 'cantidad',
          responsive: ['xs','md'],
        },        
        {
            title: 'Precio',
            dataIndex: 'precio',
            key: 'precio',
            responsive: ['xs','md'],
        },
        {
          title: 'Subtotal',
          dataIndex: 'subtotal',
          key: 'subtotal',
          responsive: ['md'],
        },
        {
            title: 'Descuento',
            dataIndex: 'descuento',
            key: 'descuento',
            responsive: ['md'],
        },        
        {
          title: 'IVA',
          dataIndex: 'impuesto4',
          key: 'impuesto4',
          responsive: ['lg'],
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            responsive: ['xs','md']
        },        
        {
          title: '',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Popconfirm
                title="¿Deseas eliminar el producto?"
                onConfirm={()=>deleteItem(record)}
                okText="Si"
                cancelText="No"
              >
              <Button shape="round" icon={<DeleteOutlined />} ></Button>
              </Popconfirm>
              
            </Space>
          ),
          responsive: ['xs','md'],
        },
      ];
      
      function deleteItem(item){
        const productosRestantes = carrito.filter( producto => producto.clave !== item.clave);        
        setCarrito(productosRestantes);
      }

      useEffect(() =>{
        setProductosCargados(true);
      })
      /*const data = [
        {
          clave: '38007010',
          producto: 'ANGULA EN ACEITE',
          cantidad: 42,
          precio:100,
          descuento: 0,
          impuestos:672,
          total:4872,
          tags: ['PALACIO DE ORIENTE', 'MARISCOS'],
        }
      ];*/

    return (
        <div>
            <br></br>
                <Title level={4}>Verifica el contenido del pedido</Title>
                <br /><br />
            <Table size={'small'} columns={columns} dataSource={carrito} />
        </div>
    )
}

export default Carrito
