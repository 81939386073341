import React from 'react'
import { Result } from 'antd';

function NotFound() {
    

    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Lo sentimos, la página que buscas no existe."
                
            />
        </div>
    )
}

export default NotFound
