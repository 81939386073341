import React, { Component }  from 'react';

import Login from './components/Login'
import ClienteBody from './components/VendedorBody'
import Pedidonuevo from './components/Pedidonuevo'
import Pedidos from './components/Pedidos'
import NotFound from './components/NotFound';
import History from './components/History';

//Vendedor
import VendedorPedidos from './components/vendedor/Pedidos';
import VendedorBody from './components/vendedor/VendedorBody';
import VendedorPedidonuevo from './components/vendedor/Pedidonuevo';
import VendedorHistory from './components/vendedor/History';

//Credito
import CreditoPedidos from './components/credito/Pedidos';
import CreditoBody from './components/credito/CreditoBody';

//Almacen
import AlmacenPedidos from './components/almacen/Pedidos';
import AlmacenBody from './components/almacen/AlmacenBody';


import {
  BrowserRouter as Router,
  Routes ,
  Route 
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>        
        <Routes >  
          <Route exact path="/" element={<Login/>} />          
          <Route exact path="/menu_principal" element={<ClienteBody />} />       
          <Route exact path="/nuevo_pedido" element={<Pedidonuevo />} /> 
          <Route exact path="/pedidos" element={<Pedidos />} />    
          <Route exact path="/timeline/:noPedido" element={<History/>} />

          <Route exact path="/vendedor/menu_principal" element={<VendedorBody />} />
          <Route exact path="/vendedor/pedidos" element={<VendedorPedidos/>} />
          <Route exact path="/vendedor/nuevo_pedido" element={<VendedorPedidonuevo/>} />
          <Route exact path="/vendedor/timeline/:noPedido" element={<VendedorHistory/>} />

          <Route exact path="/credito/menu_principal" element={<CreditoBody />} />
          <Route exact path="/credito/pedidos" element={<CreditoPedidos/>} />

          <Route exact path="/almacen/menu_principal" element={<AlmacenBody />} />
          <Route exact path="/almacen/pedidos" element={<AlmacenPedidos/>} />



        <Route exact path="*" element={<NotFound />} >
          
        </Route>

        </Routes >
      </Router>
    </div>
  );
}

export default App;
