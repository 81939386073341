import React, { useState,useEffect } from "react";
import { Table, Descriptions, Badge, Tooltip } from "antd";
import axios from 'axios';

function Confirmacion({
  header,
  carrito,
  datosOpcionales,
  setHeader,
  setCarrito,
  setDatosOpcionales,
})
 {
  const columns = [
    {
      title: "Clave",
      dataIndex: "clave",
      key: "clave",
      responsive: ['md'],
    },
    {
      title: "Producto",
      dataIndex: "descripcion",
      key: "descripcion",
      responsive: ['xs','md']
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      responsive: ['md'],
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      responsive: ['md'],
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      responsive: ['md'],
    },
    {
      title: "Descuento",
      dataIndex: "descuento",
      key: "descuento",
      responsive: ['md'],
    },
    {
      title: "IEPS",
      dataIndex: "impuesto1",
      key: "impuesto1",
      responsive: ['lg'],
    },
    {
      title: "IVA",
      dataIndex: "impuesto4",
      key: "impuesto4",
      responsive: ['lg'],
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      responsive: ['xs','md']
    },
  ];

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const totDescuentos = carrito.reduce(
    (sum, producto) => sum + producto.descuento,
    0
  );
  const totTotal = carrito.reduce((sum, producto) => sum + producto.total, 0);
  const [dir,setDir] = useState();
  const baseURL_ClienteDireccion = process.env.REACT_APP_URL_API+"/getClienteDireccion.php";

  const getDireccion = async()=>{
    await axios.get(baseURL_ClienteDireccion, {
        params: {
        clave: header.matriz
        }
    })
    .then(response=>{
        //console.log(response.data);
        setDir(response.data[0].direccion);
    }).catch(error=>{
        console.log(error);
    })
  }

  useEffect(() => {
    getDireccion();
  }, [])

  //const NewDate = moment(yourDate, 'DD-MM-YYYY');
  return (
    <div>
      
      <Descriptions
        bordered
        title="Detalles del Pedido"
        size={"small"}
        
      >
        <Descriptions.Item label="# Pedido">
          {" "}
          <Tooltip title="Una vez registrado el pedido, se asignará un # de Pedido dentro de la plataforma">
            {" "}
            <span>Por Confirmar</span>{" "}
          </Tooltip>{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Forma de Pago">
          {datosOpcionales.formaPago}
        </Descriptions.Item>
        <Descriptions.Item label="Forma de Envío">
          {datosOpcionales.formaEnvio}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha Pedido">
          {date + " " + time}
        </Descriptions.Item>
        <Descriptions.Item label="Fase">
          <Badge status="success" text="1 Toma de Pedido" />
        </Descriptions.Item>
        <Descriptions.Item label="Estatus">
          <Badge status="processing" text={header.tipo} />
        </Descriptions.Item>
        <Descriptions.Item label="Cliente">{header.matriz}</Descriptions.Item>
        <Descriptions.Item label="Envío" span={2}>
          {dir}
        </Descriptions.Item>
        <Descriptions.Item label="# Articulos">
          <Badge
            count={carrito.length}
            style={{ backgroundColor: "#EE6352" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Descuentos">
          ${totDescuentos}
        </Descriptions.Item>
        <Descriptions.Item label="Total">${totTotal}</Descriptions.Item>
        <Descriptions.Item label="Observaciones" span={3}>
          {datosOpcionales.observaciones}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <Table size={"small"} columns={columns} dataSource={carrito} />
    </div>
  );
}

export default Confirmacion;
